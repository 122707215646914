// todo cleanup
import { AssetType, Media } from '@/api'
import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg'
import { ReactComponent as CameraIcon } from '@/assets/camera.svg'
import { ReactComponent as DeleteIcon } from '@/assets/delete.svg'
import { ReactComponent as GalleryIcon } from '@/assets/gallery.svg'
import { ReactComponent as MenuIcon } from '@/assets/menu.svg'
import { ReactComponent as MicIcon } from '@/assets/mic.svg'
import { ReactComponent as PlayIcon } from '@/assets/play-audio.svg'
import { ReactComponent as PlusIcon } from '@/assets/plus.svg'
import { ReactComponent as VideoIcon } from '@/assets/video.svg'
import {
  Alert,
  AudioPlayer,
  Banner,
  Camera,
  Loader,
  PageLoader,
  Popover,
  PopoverModal,
  VideoRecorder,
} from '@/components'
import { useAuthContext, useSurveyContext } from '@/providers'
import { paths } from '@/routes/paths'
import { useDeleteMedia, useMediaList, useUploadMedia } from '@/services'
import { useItemModal, useModal, useNavigateToPreviousLocation, useOutsideClick } from '@/utils'
import { pipe, prop, reverse, sortBy } from 'ramda'
import { InputHTMLAttributes, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Preview } from './Preview'
import { RecordAudio } from './RecordAudio'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('mediaPage.title'),
    text: t('mediaPage.text'),
    button: t('mediaPage.button'),
    hint: t('mediaPage.hint'),
    galleryTitle: t('mediaPage.galleryTitle'),
    galleryPlaceholder: t('mediaPage.galleryPlaceholder'),
    camera: t('mediaPage.uploadModal.camera'),
    fromGallery: t('mediaPage.uploadModal.fromGallery'),
    video: t('mediaPage.uploadModal.video'),
    voice: t('mediaPage.uploadModal.voice'),

    uploadSuccessTitle: t('mediaPage.uploadSuccess.title'),
    uploadSuccessText: t('mediaPage.uploadSuccess.text'),
    uploadSuccessBtn: t('mediaPage.uploadSuccess.button'),
    uploadErrorTitle: t('mediaPage.uploadError.title'),
    uploadErrorText: t('mediaPage.uploadError.text'),
    uploadErrorRetry: t('mediaPage.uploadError.buttonRetry'),
    uploadErrorCancel: t('mediaPage.uploadError.buttonCancel'),

    deleteMedia: t('mediaPage.deleteMedia'),
    deleteModalTitle: t('mediaPage.deleteMediaModal.title'),
    deleteModalText1: t('mediaPage.deleteMediaModal.text1'),
    deleteModalText2: t('mediaPage.deleteMediaModal.text2'),
    deleteModalConfirm: t('mediaPage.deleteMediaModal.confirmCta'),
    deleteModalDecline: t('mediaPage.deleteMediaModal.declineCta'),
  }
}

const getAssetType = (type: string): AssetType | 'other' => {
  if (type.includes('image')) {
    return 'image'
  } else if (type.includes('video')) {
    return 'video'
  } else if (type.includes('audio')) {
    return 'audio'
  }
  return 'other'
}

const MediaItem = ({ name, id, asset_type, thumbnail, asset_optimized, sound_wave }: Media) => {
  const { currentPatient } = useAuthContext()
  const previewState = useModal(false)
  const alertState = useModal(false)
  const deleteMediaMutation = useDeleteMedia(currentPatient?.id as number, id)
  const ref = useRef<HTMLDivElement>(null)

  const handleDelete = () => {
    deleteMediaMutation.mutate({}, { onSuccess: alertState.close })
  }
  const messages = useMessages()

  useOutsideClick(ref.current, previewState.close)

  return (
    <div ref={ref} className={previewState.isOpen ? 'bg-sec10 p-2 rounded-[8px]' : ''}>
      <div
        className="py-2 flex items-center gap-2 border-t-[1px] border-t-sec10 first:border-0 cursor-pointer"
        onClick={previewState.open}
      >
        <div
          className="w-[48px] h-[48px] shrink-0 rounded-[8px] flex items-center justify-center overflow-hidden"
          style={{
            background:
              asset_type === 'audio' ? 'var(--sec10)' : asset_type === 'video' ? 'var(--b30)' : '',
          }}
        >
          {asset_type === 'audio' && <MicIcon width={16} height={22} />}
          {(asset_type === 'image' || asset_type === 'video') && thumbnail && (
            <img src={thumbnail} className="object-cover w-full h-full" alt={'Media'} />
          )}
          {asset_type === 'video' && (
            <PlayIcon width={16} height={18} className="absolute" fill="#6F7782" />
          )}
        </div>
        <div className="flex-1 text-b90 ellipsis">{name}</div>
        {currentPatient?.active && !currentPatient.is_patient_user && (
          <Popover
            actions={[{ text: messages.deleteMedia, icon: DeleteIcon, onClick: alertState.open }]}
          >
            <MenuIcon className="shrink-0 cursor-pointer" width={6} height={48} />
          </Popover>
        )}
      </div>
      {previewState.isOpen && asset_type === 'audio' && (
        <AudioPlayer audio={asset_optimized} sound_wave={sound_wave} />
      )}
      {alertState.isOpen && (
        <Alert
          title={messages.deleteModalTitle}
          variant="danger"
          contents={[messages.deleteModalText1, messages.deleteModalText2]}
          actions={[
            { text: messages.deleteModalConfirm, variant: 'danger', onClick: handleDelete },
            { text: messages.deleteModalDecline, variant: 'secondary', onClick: alertState.close },
          ]}
        />
      )}
      {previewState.isOpen && asset_type !== 'audio' && (
        <Preview url={asset_optimized} assetType={asset_type} onClose={previewState.close} />
      )}
    </div>
  )
}

const UploadingMediaItem = ({ file }: { file: File }) => {
  // todo refactor
  return (
    <div className="py-2 flex items-center gap-2 border-t-[1px] border-t-sec10 first:border-0">
      <div className="w-[48px] h-[48px] shrink-0 rounded-[8px] flex items-center justify-center overflow-hidden bg-sec10">
        <Loader />
      </div>
      <div className="flex-1 text-b90 ellipsis">{file.name}</div>
    </div>
  )
}

export const MediaPage = () => {
  const { currentPatient } = useAuthContext()
  const { data, isLoading } = useMediaList(currentPatient?.id, {
    select: pipe<[Media[]], Media[], Media[]>(sortBy(prop('id')), reverse),
  })
  const surveyContext = useSurveyContext()
  const uploadMediaMutation = useUploadMedia(currentPatient?.id as number)

  const inputFileRef = useRef<HTMLInputElement>(null)
  const previewState = useItemModal<{ file: File; assetType: AssetType }>()
  const popoverState = useModal(false)
  const videoState = useModal(false)
  const audioState = useModal(false)
  const cameraState = useModal(false)
  const successAlertState = useModal(false)
  const errorAlertState = useModal(false)

  const navigateBack = useNavigateToPreviousLocation()
  const messages = useMessages()

  const handleBack = () => {
    if (!uploadMediaMutation.isLoading) {
      navigateBack(`/${paths.home}`)
    }
  }

  const handleUploadMedia = (file: File, asset_type: AssetType) => {
    uploadMediaMutation.mutate(
      { file, asset_type },
      {
        onSuccess: successAlertState.open,
        onError: errorAlertState.open,
      },
    )
  }

  // todo refactor
  const handleGalleryClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = ''
      inputFileRef.current.click()
    }
  }

  const handleFileChange: InputHTMLAttributes<HTMLInputElement>['onChange'] = (e) => {
    const inputFile = e.target.files?.[0]
    if (inputFile) {
      handleUploadMedia(inputFile, getAssetType(inputFile.type) as AssetType)
    }
  }

  if (isLoading) return <PageLoader />

  return (
    <div className="page flex justify-center pt-[60px] bg-sec0">
      <div className="subpage-header">
        <ArrowLeft
          height={24}
          width={24}
          fill="var(--primary)"
          className={uploadMediaMutation.isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}
          onClick={handleBack}
        />
        {messages.title}
        <div />
      </div>
      <div className="max-w-[488px] flex flex-col gap-6 p-4 w-full">
        <Banner
          helperText={messages.hint}
          actions={[
            {
              onClick: popoverState.open,
              variant: 'contained',
              id: 'media-btn',
              disabled: !currentPatient?.active || currentPatient.is_patient_user,
              text: (
                <>
                  <PlusIcon width={16} height={16} className="shrink-0" />
                  {messages.button}
                </>
              ),
            },
          ]}
        >
          {messages.text}
        </Banner>
        <div className="flex flex-col gap-2">
          <div className="text-h4">{messages.galleryTitle}</div>
          {!data?.length && (
            <span className="text-textS text-b50">{messages.galleryPlaceholder}</span>
          )}
          {uploadMediaMutation.isLoading && (
            <UploadingMediaItem file={uploadMediaMutation.variables?.file!} />
          )}
          {data?.map((item) => (
            <MediaItem key={item.id} {...item} />
          ))}
        </div>
      </div>
      {cameraState.isOpen && (
        <Camera
          onCapture={(blob) => {
            const file = new File([blob], `image-${Date.now()}.jpg`, { type: 'image/jpeg' })
            previewState.openItemModal({ file, assetType: 'image' })
          }}
          onClose={cameraState.close}
        />
      )}
      {videoState.isOpen && (
        <VideoRecorder
          onCapture={async (blob) => {
            const file = new File([blob], `video-${Date.now()}.mp4`, { type: 'video/mp4' })
            previewState.openItemModal({ file, assetType: 'video' })
          }}
          onClose={videoState.close}
        />
      )}
      {audioState.isOpen && (
        <RecordAudio
          onClose={audioState.close}
          onConfirm={(blob) => {
            const file = new File(
              [blob],
              `sound_${new Date().toLocaleString().replace(/[:, ]/gi, function myFunction() {
                return '_'
              })}.mp3`,
              {
                type: blob.type,
                lastModified: Date.now(),
              },
            )
            handleUploadMedia(file, 'audio')
          }}
        />
      )}
      {previewState.isOpen && (
        <Preview
          {...previewState.item!}
          onClose={() => {
            previewState.closeItemModal()

            switch (previewState.item?.assetType) {
              case 'image':
                cameraState.open()
                break
              case 'video':
                videoState.open()
                break
            }
          }}
          onConfirm={() => {
            handleUploadMedia(previewState.item!.file, previewState.item!.assetType)
            previewState.closeItemModal()
          }}
        />
      )}
      {successAlertState.isOpen && (
        <Alert
          variant="success"
          title={messages.uploadSuccessTitle}
          contents={[messages.uploadSuccessText]}
          actions={[
            {
              text: messages.uploadSuccessBtn,
              onClick: () => {
                successAlertState.close()
                surveyContext.triggerSurvey()
              },
            },
          ]}
        />
      )}
      {errorAlertState.isOpen && (
        <Alert
          title={messages.uploadErrorTitle}
          contents={[messages.uploadErrorText]}
          actions={[
            {
              text: messages.uploadErrorRetry,
              onClick: () => {
                handleUploadMedia(
                  uploadMediaMutation.variables?.file!,
                  uploadMediaMutation.variables?.asset_type!,
                )
                errorAlertState.close()
              },
            },
            {
              text: messages.uploadErrorCancel,
              variant: 'secondary',
              onClick: errorAlertState.close,
            },
          ]}
        />
      )}
      {popoverState.isOpen && (
        <PopoverModal
          parentElement={document.getElementById('media-btn') as HTMLDivElement}
          onClose={popoverState.close}
          actions={[
            {
              icon: CameraIcon,
              text: messages.camera,
              onClick: cameraState.open,
            },
            { icon: VideoIcon, text: messages.video, onClick: videoState.open },
            {
              icon: MicIcon,
              text: messages.voice,
              onClick: audioState.open,
            },
            {
              icon: GalleryIcon,
              text: messages.fromGallery,
              onClick: handleGalleryClick,
            },
          ]}
        />
      )}
      <input
        hidden
        type="file"
        accept=".png,.jpg,.mp4,.jpeg"
        ref={inputFileRef}
        onChange={handleFileChange}
      />
    </div>
  )
}
