import { ReactComponent as Close } from '@/assets/arrow-left.svg'
import { ReactComponent as CameraSwitch } from '@/assets/camera-switch.svg'
import { useLocation } from 'react-router-dom'
import { useSnackbar } from '@/providers'
import { base64ToBlob, useModal } from '@/utils'
import { filter, pipe, propEq } from 'ramda'
import { useCallback, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import Webcam from 'react-webcam'
import { useTranslation } from 'react-i18next'

type CameraProps = {
  onCapture: (a: Blob) => void
  onClose: () => void
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    cameraPermissionError: t('global.cameraPermissionDeniedError'),
    cameraMultiFileUploadHint: t('global.cameraMultiFileUploadHint', {
      buttonOpenLibrary: t('documentTypesPopover.gallery'),
    }),
  }
}

const getAspectRatio = () => {
  const isLandscape = window.innerHeight <= window.innerWidth
  return isLandscape
    ? window.innerWidth / window.innerHeight
    : window.innerHeight / window.innerWidth
}

export const Camera = ({ onCapture, onClose }: CameraProps) => {
  const snackbar = useSnackbar()
  const facingModeState = useModal(false)
  const ref = useRef<Webcam>(null)

  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const messages = useMessages()

  const handleError = () => {
    snackbar.error(messages.cameraPermissionError)
    onClose()
  }

  const handleDevices = useCallback(
    pipe<[MediaDeviceInfo[]], MediaDeviceInfo[], void>(
      filter(propEq('videoinput', 'kind')),
      setDevices,
    ),
    [setDevices],
  )

  const handleCapture = () => {
    if (ref.current) {
      base64ToBlob(ref.current.getScreenshot()).then((value) => {
        onCapture(value)
        onClose()
      })
    }
  }

  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 bg-b100 z-[10000]">
      {useLocation().pathname.indexOf('my-space') > -1 ? (
        <div className="absolute bg-[rgba(155,155,155,0.65)] z-10 w-[100vw] top-0 text-center p-3 text-xl">
          {messages.cameraMultiFileUploadHint}
        </div>
      ) : (
        ''
      )}
      <Webcam
        ref={ref}
        audio={false}
        screenshotFormat="image/jpeg"
        forceScreenshotSourceSize
        className={`w-full h-full object-cover transition-all ${
          facingModeState.isOpen ? 'animate-camera-back' : 'animate-camera-front'
        }`}
        videoConstraints={{
          facingMode: facingModeState.isOpen ? 'environment' : 'user',
          width: isMobile ? window.innerHeight : window.innerWidth,
          height: isMobile ? window.innerWidth : window.innerHeight,
          aspectRatio: getAspectRatio(),
        }}
        mirrored={!facingModeState.isOpen}
        onUserMediaError={handleError}
        onUserMedia={() =>
          navigator.mediaDevices.enumerateDevices().then(handleDevices).catch(handleError)
        }
      />
      <div className="backdrop-blur-[5px] flex flex-col-reverse gap-[56px] justify-center items-center w-[108px] border-l-[1px] border-b10 fixed top-0 right-0 bottom-0 sm:flex-row sm:top-[unset] sm:w-screen sm:px-4 sm:gap-2 sm:justify-between sm:border-t-[1px] sm:border-l-0 sm:h-[92px] sm:items-center sm:pt-2">
        <div className="flex items-center justify-center w-[48px] h-[48px] border-[1px] border-b0 rounded-[50%] bg-b90">
          <Close
            width={24}
            height={24}
            fill="var(--b0)"
            className="cursor-pointer"
            onClick={onClose}
          />
        </div>
        <img
          src="/assets/camera-capture.png"
          className="cursor-pointer"
          onClick={handleCapture}
          alt="camera"
        />
        {devices.length > 1 ? (
          <div className="flex items-center justify-center w-[48px] h-[48px] border-[1px] border-b0 rounded-[50%] bg-b90">
            <CameraSwitch
              width={24}
              height={24}
              fill="var(--b0)"
              className="cursor-pointer"
              onClick={facingModeState.toggle}
            />
          </div>
        ) : (
          <div className="w-[48px]" />
        )}
      </div>
    </div>
  )
}
