import { ReactComponent as CameraIcon } from '@/assets/camera.svg'
import { ReactComponent as DocumentIcon } from '@/assets/document.svg'
import { ReactComponent as GreenMark } from '@/assets/green-mark.svg'
import { Camera, PopoverModal } from '@/components'
import { paths } from '@/routes/paths.ts'
import { useDocumentTypes, useUploadDocuments } from '@/services'
import { reactQueryKeys, useItemModal, useModal } from '@/utils'
import {
  FunctionComponent,
  InputHTMLAttributes,
  SVGProps,
  useEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Preview } from '../MediaPage/Preview'
import { ChosenDocumentType } from './DocumentSpace'
import { allowedDocumentTypes } from '@/api'
import { useQuery } from 'react-query'
import { useSnackbar } from '@/providers'
import { isMobile } from 'react-device-detect'

const GetAllowedDocumentTypes = (patientId?: string) =>
  useQuery(
    [reactQueryKeys.allowedDocumentTypes, patientId],
    () => allowedDocumentTypes(patientId!),
    {
      enabled: Boolean(patientId),
    },
  )

type DocumentUploadPopoverContentProps = {
  documentType: ChosenDocumentType
  onClose: () => void
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('documentTypesPopover.title'),
    camera: t('mediaPage.uploadModal.camera'),
    gallery: t('documentTypesPopover.gallery'),
    msgSuccess: t('documentTypesPopover.msgSuccess'),
    msgError: t('global.messageError'),
  }
}

export const DocumentUploadPopoverContent = ({
  documentType,
  onClose,
}: DocumentUploadPopoverContentProps) => {
  const snackbar = useSnackbar()
  const { patientId } = useParams()
  const navigate = useNavigate()
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState(false)
  const uploadDocumentMutation = useUploadDocuments(patientId as string)
  const popoverState = useModal(true)
  const cameraState = useModal(false)
  const previewState = useItemModal<File>()
  const messages = useMessages()
  const documentRequestId = isNaN(Number(documentType.id)) ? '' : documentType.id
  const documentTypeId = documentRequestId ? '' : documentType.id

  const handleUploadMedia = (file: File[]) => {
    setIsLoading(true)

    uploadDocumentMutation.mutate(
      { file, document_request: documentRequestId, document_type: documentTypeId },
      {
        onSuccess: () => {
          //toast here
          snackbar.success(messages.msgSuccess)
          navigate(`/${paths.home}/${patientId}/${paths.chat}`)
          setIsLoading(false)
        },
        onError: () => {
          snackbar.error(messages.msgError)
          setIsLoading(false)
        },
      },
    )
  }

  const handleGalleryClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = ''
      inputFileRef.current.click()
    }
  }

  const handleFileChange: InputHTMLAttributes<HTMLInputElement>['onChange'] = (e) => {
    if (e.target.files) {
      const inputFile = [...e.target.files]
      if (inputFile) {
        handleUploadMedia(inputFile)
      }
    }
  }

  useEffect(() => {
    if (
      !popoverState.isOpen &&
      !cameraState.isOpen &&
      !previewState.isOpen &&
      !uploadDocumentMutation.isLoading
    ) {
      onClose()
    }
  }, [
    popoverState.isOpen,
    cameraState.isOpen,
    previewState.isOpen,
    uploadDocumentMutation.isLoading,
  ])

  let buttonActions:
    | {
        onClick: () => void
        text: string
        badge?: boolean | undefined
        icon?: FunctionComponent<SVGProps<SVGSVGElement>> | undefined
        hide?: boolean | undefined
        disabled?: boolean | undefined
        id?: string | undefined
      }[]
    | {
        text: string
        icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>
        onClick: () => void
      }[] = []
  if (isMobile) {
    buttonActions = [{ text: messages.gallery, icon: DocumentIcon, onClick: handleGalleryClick }]
  } else {
    buttonActions = [
      { text: messages.camera, icon: CameraIcon, onClick: cameraState.open },
      { text: messages.gallery, icon: DocumentIcon, onClick: handleGalleryClick },
    ]
  }

  return (
    <>
      {popoverState.isOpen && (
        <PopoverModal
          title={documentType.name}
          onClose={popoverState.close}
          actions={buttonActions}
          closeOnSelect={false}
          isLoading={isLoading}
        />
      )}
      {(cameraState.isOpen || previewState.isOpen) &&
        createPortal(
          <>
            {cameraState.isOpen && (
              <Camera
                onCapture={(blob) => {
                  const file = new File([blob], `${documentType.name}.jpg`, { type: 'image/jpeg' })
                  previewState.openItemModal(file)
                }}
                onClose={cameraState.close}
              />
            )}
            {previewState.isOpen && (
              <Preview
                assetType="image"
                file={previewState.item!}
                onClose={() => {
                  previewState.closeItemModal()
                  cameraState.open()
                }}
                onConfirm={() => {
                  handleUploadMedia([previewState.item!])
                  previewState.closeItemModal()
                }}
              />
            )}
          </>,
          document.body,
        )}
      <input
        hidden
        type="file"
        accept="image/*,.pdf"
        multiple={true}
        ref={inputFileRef}
        onChange={handleFileChange}
      />
    </>
  )
}

type PatientPopoverContentProps = {
  onClose: () => void
  onSelect: (documentType: ChosenDocumentType) => void
  freeUpload?: boolean
}

export const DocumentTypesModal = ({
  onClose,
  onSelect,
  freeUpload,
}: PatientPopoverContentProps) => {
  const messages = useMessages()
  // const cameraState = useModal(false)
  const { patientId } = useParams()
  const { data: allowedDocumentTypes } = GetAllowedDocumentTypes(
    patientId ? patientId.toString() : undefined,
  )
  const { data: documentRequests = [] } = useDocumentTypes(patientId as string)

  const data =
    freeUpload && allowedDocumentTypes && allowedDocumentTypes.medical_document_types
      ? allowedDocumentTypes.medical_document_types
      : documentRequests

  return (
    <PopoverModal
      contentClassName="max-h-[496px]"
      title={messages.title}
      onClose={onClose}
      // @ts-ignore
      actions={data.map(({ id, name, has_an_uploaded_document }) => ({
        text: name,
        icon: has_an_uploaded_document ? GreenMark : undefined,
        id: id,
        onClick: () => {
          onSelect({ id, name })
        },
        disabled: has_an_uploaded_document,
      }))}
      parentElement={null}
    />
  )
}
