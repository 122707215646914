import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg'
import { useModal } from '@/utils'
import { pipe, prop, reject } from 'ramda'
import { FunctionComponent, ReactElement, SVGProps, cloneElement, useRef } from 'react'
import { Modal } from '../Modal'
import { PageLoader } from '@/components'

type popoverActionType = {
  onClick: () => void
  text: string
  badge?: boolean
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  hide?: boolean
  disabled?: boolean
  id?: string
}

type Popover = {
  children: ReactElement<{ onClick: (e: Event) => void }>
  className?: string
}

type PopoverModal = {
  onClose: () => void
  contentClassName?: string
  parentElement?: HTMLDivElement | null
  closeOnSelect?: boolean
  isLoading?: boolean
}

type PopoverBase = {
  title?: string
  actions: popoverActionType[]
  customPopoverContent?: never
}

type CustomPopover = {
  title?: string
  customPopoverContent: FunctionComponent<{ onClose: () => void }>
  actions?: never
}

type WithPopoverProps<T> = (T & PopoverBase) | (T & CustomPopover)

const ArrowRight: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <ArrowLeft {...props} className="rotate-180" />
)

export const PopoverModal = ({
  onClose,
  title,
  actions,
  customPopoverContent: CustomPopoverContent,
  parentElement = null,
  contentClassName,
  closeOnSelect = true,
  isLoading = false,
}: WithPopoverProps<PopoverModal>) => (
  <Modal title={title} onClose={onClose} parentElement={parentElement}>
    {!isLoading ? (
      CustomPopoverContent ? (
        <CustomPopoverContent onClose={onClose} />
      ) : (
        <div className={`flex flex-col w-full ${contentClassName}`}>
          {reject(pipe(prop('hide')), actions).map(
            ({ text, onClick, badge, id, icon: Icon = ArrowRight, disabled }) => (
              <div
                key={`${text}_${id}`}
                className="h-[62px] shrink-0 flex justify-between items-center gap-2 border-b-[1px] border-b-sec10 text-b90 cursor-pointer"
                onClick={() => {
                  if (!disabled) {
                    onClick()
                    if (closeOnSelect) onClose()
                  }
                }}
              >
                {text}
                <div className="flex gap-2 items-center child:shrink-0">
                  {badge && <div className="badge-danger" />}
                  <Icon width={24} height={24} fill="var(--primary)" />
                </div>
              </div>
            ),
          )}
        </div>
      )
    ) : (
      <PageLoader width={60} position={'relative'} />
    )}
  </Modal>
)

export const Popover = ({
  children,
  title,
  actions,
  customPopoverContent,
  className,
}: WithPopoverProps<Popover>) => {
  const popoverState = useModal(false)

  const containerRef = useRef<HTMLDivElement>(null)

  const handleClick = (e: Event) => {
    e.preventDefault()
    e.stopPropagation()
    popoverState.open()
  }

  const clonedChildren = cloneElement(children, { onClick: handleClick })

  return (
    <div className={`relative ${className || ''}`} ref={containerRef}>
      {clonedChildren}
      {popoverState.isOpen && (
        <PopoverModal
          onClose={popoverState.close}
          title={title}
          parentElement={containerRef.current}
          {...(customPopoverContent ? { customPopoverContent } : { actions })}
        />
      )}
    </div>
  )
}
