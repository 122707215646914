import { api } from './api'
import { RelationType } from './patient'
import { Profile } from './user'

export type SettingsType = {
  relationships: { value: RelationType; label: string }[]
  languages: { value: Profile['language']; label: string }[]
}

export const getSettings = () => api.get<SettingsType>('/contacts/hospitals/get_settings/')

export type DocumentType = {
  id: string
  name: string
  has_an_uploaded_document: boolean
  creation_time: string
}

export type AllowedDocumentType = {
  documents_requests_enabled: boolean
  medical_document_types: [id: string, name: string]
}

export const getDocumentTypes = (patientId: string) =>
  api.get<DocumentType[]>(`/contacts/medical_documents/${patientId}/documents_types/`)

export const allowedDocumentTypes = (patientId: string) =>
  api.get<AllowedDocumentType>(`/contacts/patients/${patientId}/medical_document_types/`)

export const uploadDocuments = (
  id: string,
  files: File[],
  document_request: string,
  document_type: string,
) => {
  const formData = new FormData()

  if (document_request) formData.append('document_request', document_request)
  if (document_type) formData.append('document_type', document_type)
  for (const file of files) {
    formData.append(`file`, file)
    formData.append(`title`, file.name)
  }

  return api.post(`/contacts/medical_documents/${id}/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
